@import 'libs/styles/src/common/common';

@layer shared-component {
  .what {
    margin: -0.25rem 0 0 0.5rem;
    cursor: pointer;

    > :first-child {
      padding: 3px;
      display: inline-block;

      > svg {
        width: 18px;
        height: 18px;
      }
    }
  }

  .title {
    // tooltip-title
    font-family: var(--font-family-sans-serif) !important;
    font-weight: bold;
    font-size: 0.625rem !important;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: $blue !important;
    margin-bottom: 1rem !important;
  }

  .body {
    // tooltip-body
    font-family: var(--font-family-sans-serif) !important;
    font-weight: normal;
    font-size: .75rem !important;
    color: $dark-blue-text !important;

    > *:last-child {
      margin-bottom: 0;
    }
  }
}
