@import 'libs/styles/src/common/common';

@layer page {
  /* Sign Up */
  .btn {
    min-width: 135px;
    font-weight: 500;
    font-size: 13px;
    background: $button-blue;
    color: #fff;
    text-align: left;
    display: inline-block;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.875rem 1.375rem;
    border-radius: 90px;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    cursor: pointer;

    &:hover {
      text-decoration: none;
      background: $button-dark-blue;
      color: #fff;
    }

    &:active,
    &:focus {
      background: $button-light-blue;
      color: #fff;
      box-shadow: none;
      outline: none;
    }

    &--secondary {
      background: $button-secondary-blue;
      color: $button-text-blue;

      &:active,
      &:focus,
      &:hover {
        background: $button-light-blue;
        color: $button-text-blue;
      }

      &--disabled,
      &:disabled,
      *[disabled] {
        color: $button-text-gray;
      }
    }

    &--icon {
      min-width: unset !important;
    }

    &--invert {
      background: #fff;
      color: $blue;

      &:hover {
        background: $button-light-blue;
        color: $button-text-blue;
      }

      &:active,
      &:focus {
        background: #fff;
        color: $blue;
      }
    }

    &--transparent {
      background: transparent;
      border-radius: none;

      &:hover {
        background: transparent;
      }

      &:active,
      &:focus {
        background: transparent;
        box-shadow: none;
        outline: none;
      }

      &--disabled,
      &:disabled,
      *[disabled] {
        background: transparent;
      }
    }

    &--text {
      border-radius: 0;
      font-size: 1rem;
      font-weight: 600;
      padding: 0;
      background: none;
      min-width: 0;
      color: $dark-blue-text;

      &:hover {
        text-decoration: underline;
        color: $blue;
        padding: 0;
        background: none;
      }
    }

    &--large {
      min-width: 265px;
    }

    &--small {
      min-width: 90px;
      padding: 0.6rem 1.25rem;
    }

    &--inline {
      min-width: 0;
      white-space: nowrap;
    }

    &--block {
      width: 100%;
    }

    &--social {
      width: 100%;
      overflow: hidden;
      background: white;
      border: 1px solid black;
      font-weight: bold;
      border-radius: 0;
      text-align: center;
      color: black;
      padding: 0.3em;
      font-size: 0.8em;

      svg {
        margin-right: 0.7em;
        width: 20px;
        height: 20px;
        vertical-align: bottom;
      }

      &:hover {
        background: $pale-blue;
        color: black;
        font-weight: bold;
      }
    }

    &--mr-2 {
      margin-right: 0.2rem;
    }

    &--mt-1 {
      margin-top: 1rem;
    }

    &--shadow {
      box-shadow: $small-shadow;
    }

    &--arrow {
    }

    &--disabled,
    &:disabled,
    *[disabled] {
      background: $button-gray;
      color: $button-text-gray;
      opacity: 0.65;
      cursor: default;
    }
  }

  .div-link {
    outline: 0;
    cursor: pointer;
    user-select: none;

    &:focus {
      text-decoration: underline;
    }
  }

  .learn-more {
    outline: none;
    background: transparent;
    border: none;
    white-space: nowrap;
    font-weight: bold;
    font-size: 11px;
    letter-spacing: 1px;
    color: #3f5aa9;
    text-transform: uppercase;
    text-decoration: none;
    float: right;
    padding: 0;

    svg {
      margin-left: 0.5rem;
    }

    &:hover {
      color: $dark-blue-text;
      text-decoration: none;
    }
  }

  .expand-button-icon {
    padding: 0;
    white-space: nowrap;
    font-weight: bold;
    font-size: 11px;
    letter-spacing: 1px;
    color: $blue;
    text-transform: uppercase;
    text-decoration: none;

    svg {
      transform-origin: center;
      transform: rotate(45deg);
      transition: transform 0.1s linear;
    }
  }

  .expanded-icon svg {
    transform-origin: center;
    transform: rotate(0deg);
    transition: transform 0.1s linear;
  }

  .btn-text {
    cursor: pointer;

    &--secondary {
      color: $blue;
    }

    &--wrap {
      word-break: break-word;
    }
  }
}
