@import 'libs/styles/src/common/common';

@layer component {
  .social-media {
    h3 {
      font-size: 1em;
      margin-bottom: 0.5em;
      margin-top: 0.5em;
    }

    width: 100%;
    margin: 0 auto;

    &__buttons {
      button {
        margin-bottom: 0.5rem;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &__link {
      color: $blue;
      white-space: nowrap;
      width: 30px;
      height: 30px;

      &--text {
        color: $blue;
        margin-left: 0.25em;
      }
    }

    &__or {
      text-align: center;
      padding: 1rem 0 0 0;
      position: relative;
      font-size: 0.8em;
    }

    &__or::before {
      position: absolute;
      display: inline-block;
      content: '';
      left: 0;
      top: 68%;
      width: 46%;
      height: 0;
      border-bottom: 1px solid #dbdbdb;
    }

    &__or::after {
      position: absolute;
      display: inline-block;
      content: '';
      right: 0;
      top: 68%;
      width: 46%;
      height: 0;
      border-bottom: 1px solid #dbdbdb;
    }
  }
}
