@import 'libs/styles/src/common/common';

$box-shadow: 0 3px 6px 0 $hr-color;

@layer component {
  .feedback {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 0.8rem;
    color: $dark-blue-text;
    font-weight: normal;

    &--invalid {
      color: $red;
    }

    &--notice {
      color: $blue;
      font-weight: 700;
    }
  }

  .form {
    label {
      margin-bottom: 0.5rem;
      user-select: none;
    }

    fieldset {
      border: 0;
      margin: 1.5rem 0;

      @include media-breakpoint-down(md) {
        padding: 0;
      }

      legend {
        position: relative;
        display: block;
        font-weight: bold;
        font-size: 12px;
        letter-spacing: 2px;
        text-transform: uppercase;
        color: #3a3f58;
        width: 100%;

        &::before {
          content: '';
          position: absolute;
          top: 1.2rem;
          left: 0;
          right: 0;
          height: 1px;
          width: 100%;
          background: $light-gray;
          z-index: 0;
        }

        span {
          position: relative;
          z-index: 1;
          background: #fff;
          padding-right: 1.5rem;
        }

        span.step {
          display: inline-block;
          margin-right: 0.5rem;
          padding: 0;
          height: 30px;
          width: 30px;
          border-radius: 50%;
          font-weight: bold;
          font-size: 12px;
          text-align: center;
          color: #fff;
          background: $blue;
        }
      }
    }
  }

  .form-control {
    background: transparent;
    appearance: none;
    border: 1px solid #c5cde5;
    border-radius: 90px;
    outline: none;
    padding: 0.6rem 2.5rem 0.6rem 1.25rem;
    font-weight: 400;
    font-size: 16px;
    font-family: var(--font-family-sans-serif);
    width: 100%;
    color: $dark-blue-text;
    box-shadow: $box-shadow;

    &--placeholder {
      color: #7e9098;
    }

    &--selected {
      color: $dark-blue-text;
    }

    &:disabled {
      background: $light-disabled-grey;
    }

    option {
      color: $dark-blue-text;
    }
  }

  .form-group {
    position: relative;
    margin-bottom: 1rem;

    &.no-margin {
      margin-bottom: unset;
    }

    .form-multi-group {
      width: 100%;
      padding: 0;
      display: flex;
      gap: 0 10px;

      > div:not(:last-child) {
        @include media-breakpoint-down(xs) {
          margin-bottom: 1rem;
        }
      }

      @include media-breakpoint-down(xs) {
        display: flex;
        flex-flow: column;
      }
    }

    input[type='checkbox'],
    input[type='radio'] {
      // box-shadow: $box-shadow;
      accent-color: $blue-alt-1;
    }

    div.form-label,
    label.form-label,
    span.form-label {
      display: flex;
      min-height: 24px;
      align-items: center;
      justify-items: center;
      margin-bottom: 2px;
      margin-right: 12px;
      font-weight: bold;
      font-size: 14px;
      color: var(--form-label-color, $form-label);
      border-radius: 67px;

      &.text-area-label {
        top: -1px;
        left: 0;
        right: 0;
        padding: 10px 0;
        margin-right: 0;
        border-radius: 0;
      }
    }

    .radio-label {
      font-size: 15px;
      color: $dark-blue-text;
      margin: 0.5em 0;

      input[type='radio'] {
        position: relative;
        top: 2px;
        margin-right: 0.5em;
      }
    }

    .form-padding {
      padding: 4px 12px;
    }

    input,
    select,
    textarea,
    .form-input {
      &.form-control {
        border: none;
        border-radius: 0;
        padding: 4px 12px;
        border-bottom: 2px solid $blue;

        &:focus {
          outline: none;
          box-shadow: $box-shadow;
        }

        &::placeholder {
          font-weight: normal;
        }

        &.no-border {
          border: none;
          border-radius: 0;
          box-shadow: none;
        }

        &.is-wrapper {
          padding: 0;
        }

        &[disabled] {
          background: $light-disabled-grey;
        }
      }
    }

    input.form-control,
    textarea.form-control {
      &:focus {
        background: $pale-blue;

        + button {
          background: $pale-blue;
        }
      }

      &[disabled] {
        + button {
          background: $light-disabled-grey;
        }
      }
    }

    &--select {
      select.form-control {
        background: #fff;
        background-position: calc(100% - 1rem) 50%;
        background-repeat: no-repeat;
        background-image: url(data:image/svg+xml,%3Csvg%20width%3D%2214%22%20height%3D%2212%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2014%2012%22%3E%0A%20%20%3Cpath%20fill%3D%22%234461B7%22%20d%3D%22M3.86%207.93L0%204.07h7.72z%22%2F%3E%0A%3C%2Fsvg%3E);
        appearance: none;

        option {
          padding: 0.25rem 0.5rem;
        }
      }
    }

    &--error {
      input.form-control,
      text.form-control,
      textarea.form-control,
      select.form-control,
      .form-input.form-control {
        // to prevent "doubling up" of colors for nested form-controls
        background: #fdd5dd url(../assets/icon-error.svg) 96% 50% no-repeat;
        border-color: $red;

        + button {
          background: #fdd5dd;
        }

        > .form-control {
          background: unset;
          border: unset;
        }
      }
    }
  }

  .extra-links {
    float: right;
    margin-top: -10px;
    font-size: 0.8em;
  }

  .char-count {
    float: right;
    font-size: 0.8em;
    font-style: italic;
    color: rgba($dark-blue, 0.7);
  }

  .text-right {
    text-align: right;
  }

  .text-center {
    text-align: center;
  }

  .text-left {
    text-align: left;
  }

  .form-check {
    padding: 18px 12px 6px;
  }

  .hint-box {
    background: #f8f8f8;
    padding: 19px 2.5rem;
    margin: 1rem 0;

    @media (max-width: 350px) {
      padding: 10px 1rem;
    }

    ul {
      margin: 0;
      padding: 0 1rem;
    }

    p,
    li {
      font-weight: bold;
      font-size: 14px;
      opacity: 0.7;
      color: $dark-blue-text;
      margin-bottom: 0;
    }
  }

  .alert {
    &-danger {
      padding: 0.5rem;
      margin: 0.75rem;
      color: $red;
      border: 1px solid rgb(245 46 77 / 40%);
      background: rgb(245 46 77 / 10%);
    }

    &-success {
      padding: 0.5rem;
      margin: 0.75rem;
      color: $blue;
      border: 1px solid #c2cbff;
      background: $pale-blue;
    }

    &-warning {
      padding: 0.5rem;
      margin: 0.75rem;
      color: rgb(141 72 29);
      border: 1px solid rgb(245 185 46 / 40%);
      background: rgb(245 185 46 / 10%);
    }

    &.no-margin {
      margin: 0;
    }
  }

  .text-warning {
    color: $super-light-red;
    border: none;
    font-weight: bold;
    text-decoration: underline;
  }

  .pagination {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 1em;
  }

  .pagination-buttons {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
  }

  // autocomplete overrides
  .menu-wrapper {
    background: unset;
    box-shadow: unset;
    border-radius: unset;
  }

  .input-wrapper {
    border: unset;
  }

  .field-button-center {
    display: flex;
    align-items: center;
  }

  .action {
    align-self: center;

    // margin-bottom: 6px;
    margin-left: 0.5rem;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: initial;
  }
}
