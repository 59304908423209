@import 'libs/styles/src/common/common';

@layer component {
  .auth-form {
    width: 350px;
    max-width: 100%;
    margin: 0.5rem auto;

    h2 {
      text-align: center;
    }
  }

  .text-right {
    text-align: right;
  }

  .social-login {
    width: 350px;
    max-width: 100%;
    margin: 0 auto;
  }

  .fieldset-border {
    border: none;
    padding: 0;
    margin: 0;
  }

  .menu-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 6rem 0.5rem 0.5rem;
    width: 100%;

    &--tnc {
      padding-top: 0.5rem;
      font-size: 0.8em;
      align-items: flex-start;
    }
  }
}
